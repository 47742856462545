import {
  COMPLETE_TEST_SUCCESS,
  GET_TEST_QESTION_REQUEST,
  GET_TEST_QESTION_SUCCESS,
  POST_TEST_ANSWER_REQUEST,
  POST_TEST_ANSWER_SUCCESS
} from '../actions/test'

const initialState = {
  questions: []
}

export const test = (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case GET_TEST_QESTION_REQUEST:
      return { ...state, questions: [] }
    case GET_TEST_QESTION_SUCCESS:
      return { ...state, questions: action.payload, finalMsg: undefined }
    case POST_TEST_ANSWER_SUCCESS:
      let questionsSuccess = [...state.questions]
      let questionSuccess =
        questionsSuccess[
          questionsSuccess.findIndex((element, index, array) => {
            return element.id === action.meta.questionId
          })
          ]
      let answerSuccess =
        questionSuccess.answers[
          questionSuccess.answers.findIndex((element, index, array) => {
            return element.id === action.meta.answerId
          })
          ]
      questionSuccess.status = action.payload.status ? 'success' : 'failure'
      answerSuccess.status = action.payload.status ? 'success' : 'failure'
      answerSuccess.statusText = action.payload.statusText
        ? action.payload.statusText
        : ''
      return { ...state, questions: questionsSuccess }

    case POST_TEST_ANSWER_REQUEST:
      let questionsRequest = [...state.questions]
      let questionRequest =
        questionsRequest[
          questionsRequest.findIndex((element, index, array) => {
            return element.id === action.meta.questionId
          })
          ]
      let answerRequest =
        questionRequest.answers[
          questionRequest.answers.findIndex((element, index, array) => {
            return element.id === action.meta.answerId
          })
          ]
      questionRequest.status = 'pending'
      answerRequest.status = 'pending'
      return { ...state, questions: questionsRequest }
    case COMPLETE_TEST_SUCCESS:
      return { ...state, questions: [], finalMsg: action.payload }
    default:
      return state
  }
}
