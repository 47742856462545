import React, { useState, useEffect } from 'react';
import './CookieBanner.scss';

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = document.cookie.split('; ').find(row => row.startsWith('cookie_allow='));
    if (!cookieConsent) {
      setIsVisible(true);
    }
  }, []);

  const setCookie = (value) => {
    document.cookie = `cookie_allow=${value};max-age=2000000`;
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="cookie-banner" role="dialog" aria-label="Cookie notice banner" tabIndex="1">
      <div className="cookie-banner__body">
        <div className="cookie-banner__texts">
          <h2 className="cookie-banner__title">О файлах cookie на этом веб-сайте</h2>
          <p className="cookie-banner__message">
            Вы даёте согласие на обработку файлов cookie, пользовательских данных (сведения о местоположении; тип и версия ОС; тип и версия браузера; тип устройства и разрешение его экрана; источник откуда пришел на сайт пользователь; с какого сайта; язык ОС и браузера; какие страницы открывает и на какие кнопки нажимает пользователь; ip-адрес) в целях профилирования посетителей сайта, получения статистических данных о посещении сайта. Если вы не хотите, чтобы ваши данные обрабатывались, покиньте сайт. С более подробной информацией о cookie вы можете ознакомиться <a href="https://www.purina.ru/data-policy" target="_blank">по&nbsp;ссылке</a>.
          </p>
        </div>
        <div className="cookie-banner__buttons">
          <button type="button" className="cookie-banner__agree" onClick={() => setCookie(1)}>Согласен</button>
        </div>
      </div>
      <div className="cookie-banner__background"></div>
    </div>
  );
};

export default CookieBanner;